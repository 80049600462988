import React, { Fragment, useLayoutEffect } from "react";
import { graphql, navigate } from "gatsby";
import Spin from "../../components/Spin";
import Button from "../../components/Button";
import { postUpsells } from "../../services/account.service";
import useOrderSummary from "../../hooks/useOrderSummary";
import cart from "../../utils/cart";
import SEO from "../../components/SEO";
import "../../styles/_all.scss";

export const query = graphql`
  query confirmPageQuery {
    settings: sanitySiteSettings {
      title
      id
      facebook
      instagram
      twitter
      _rawOpenGraph(resolveReferences: { maxDepth: 10 })
      _rawLogo(resolveReferences: { maxDepth: 10 })
    }
  }
`;

export default ({ data }) => {
  const productId = cart.get("product_id");
  const accountId = cart.get("account_id");

  useLayoutEffect(() => {
    if (!productId || !accountId) {
      navigate("/404");
    }
  }, []);

  const {
    loading,
    plan,
    offers,
    immediate,
    immediateTotal,
    yearly,
    yearlyTotal,
    monthly,
    monthlyTotal,
  } = useOrderSummary(productId);

  const onComplete = () => {
    if (offers.length) {
      return postUpsells(
        accountId,
        offers.map((i) => i.key)
      ).then(
        () => {
          navigate("/checkout/complete");
        },
        (e) => alert(e.message)
      );
    } else {
      navigate("/checkout/complete");
    }
  };

  if (loading) return <Spin spinning />;

  return (
    <Fragment>
      <SEO
        title="Order Summary"
        siteName={data.settings.title}
        description={data.settings.description}
      />
      <div className="section-spacer-bottom shape-background shape-background-dots shape-background-left">
        <div className="row text-center">
          <div className="col-xs-16 col-xs-offset-1 col-md-10 col-md-offset-4">
            <h1 className="text-blue">Order Summary</h1>
          </div>
        </div>
        <div className="row">
          <div className="col-xs-16 col-xs-offset-1 col-md-10 col-md-offset-4">
            <div className="checkout-cart">
              <div className="checkout-cart-row">
                <strong>Your Subscription</strong>
              </div>
              <div className="checkout-cart-row">
                <span>{plan.name}</span>
                <span>
                  ${plan.price} per {plan.freq}
                </span>
              </div>
              <div className="checkout-cart-row">
                <strong>Your Add-ons</strong>
              </div>
              {immediate.map((o) => (
                <div className="checkout-cart-row">
                  <span>{o.offer_statement_title}</span>{" "}
                  <span>${o.offer_cost} today</span>
                </div>
              ))}
              {monthly.map((o) => (
                <div className="checkout-cart-row">
                  <span>{o.product_name}</span>{" "}
                  <span>${Number(o.product_amount)} per month</span>
                </div>
              ))}
              {yearly.map((o) => (
                <div className="checkout-cart-row">
                  <span>{o.product_name}</span>{" "}
                  <span>${Number(o.product_amount)} per year</span>
                </div>
              ))}
              <div className="checkout-cart-row-footer">
                <span>
                  <strong>Total:</strong>
                </span>{" "}
                <span className="pull-right-md">
                  {immediateTotal ? `$${immediateTotal} today` : ""}
                  {immediateTotal && (monthlyTotal || yearlyTotal)
                    ? " and then "
                    : ""}
                  {monthlyTotal ? `$${monthlyTotal} per month ` : ""}
                  {yearlyTotal ? `$${yearlyTotal} per year ` : ""}
                  {plan.trialDuration
                    ? `(after your ${plan.trialDuration} days trial)`
                    : ""}
                </span>
              </div>
            </div>
            <div className="text-center" id="checkoutOffer">
              <Button onClick={onComplete}>Get Started</Button>
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};
